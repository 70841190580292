<template>
  <div class="nameAndSubscribe">
    <p class="nameAndSubscribe-name">{{ node_name_select }}</p>
    <template v-if="is_show">
      <el-button
        v-if="subscribeNodeName.indexOf(node_name_select) != -1"
        class="btn"
        size="mini"
        type="primary"
        @click="cancelSubscribe"
        :loading="cancelLoading"
        >已订阅</el-button
      >
      <el-button
        v-if="subscribeNodeName.indexOf(node_name_select) == -1"
        class="btn"
        size="mini"
        type="primary"
        plain
        icon="el-icon-plus"
        @click="addUserSubscribe"
        :loading="subscribeLoading"
        >订阅</el-button
      >
    </template>
  </div>
</template>

<script>
import api from "@/api/api";
import cookie from "@/utils/common.js";
import { EventBus } from "../utils/eventBus.js";

export default {
  name: "BtnSubscribe",
  props: {
    subscribeNodeName: Array,
    node_name_select: String,
    domain_id_select: Number,
    is_show: Boolean,
  },
  data() {
    return {
      cancelLoading: false,
      subscribeLoading: false,
    };
  },
  methods: {
    // 获取某元素在数组中的下标
    getArrIndex(arr, val) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] == val) return i;
      }
      return -1;
    },
    // 删除数组的某元素
    arrRemove(arr, val) {
      let index = this.getArrIndex(arr, val);
      if (index > -1) {
        arr.splice(index, 1);
      }
    },
    // 取消订阅 (订阅类型：option)
    cancelSubscribe() {
      this.$confirm("您确定要取消订阅吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.cancelLoading = true;
          let params = {
            domain_id: this.domain_id_select,
            account: cookie.getCookie("account"),
            subscribe_type: "option",
          };
          api
            .cancelSubscribe(params)
            .then((res) => {
              if (res.status == 200) {
                this.$message({
                  type: "success",
                  message: "取消订阅成功!",
                });
                this.arrRemove(this.subscribeNodeName, this.node_name_select);
                EventBus.$emit("cancelSubscribe", this.node_name_select);
              }
              this.cancelLoading = false;
            })
            .catch((err) => {
              this.cancelLoading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "操作取消",
          });
        });
    },
    // 添加订阅
    addUserSubscribe() {
      let params = {
        domain_id: this.domain_id_select,
        account: cookie.getCookie("account"),
      };
      this.subscribeLoading = true;
      api
        .addUserSubscribe(params)
        .then((res) => {
          if (res.status == 200) {
            this.$message.success("添加订阅成功！");
            this.subscribeNodeName.push(this.node_name_select);
            let node = {
              name: this.node_name_select,
              node_id: this.domain_id_select,
            };
            EventBus.$emit("addUserSubscribe", node);
          } else {
            this.$message.error("添加订阅失败！");
          }
          this.subscribeLoading = false;
        })
        .catch((err) => {
          this.subscribeLoading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.nameAndSubscribe {
  display: flex;
  width: 100%;
  margin-top: 10px;
  // justify-content: space-between;
  align-items: center;
  &-name {
    font-size: 16px;
    color: #333;
    margin-left: 20px;
    margin-right: 10px;
    font-weight: bold;
  }
}
</style>
