<template>
  <div class="companyRelationLink">
    <div class="graphContainer">
      <div class="graphChartCompany" ref="graphChartCompany"></div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../utils/eventBus.js";
export default {
  name: "CompanyRelationLink",
  props: {
    structureData: Object,
  },
  data() {
    return {
      maxNum: 0,
      graphChart: "",
      graphData: [
        {
          // id: 100,
          name: "test0",
          category: 0,
        },
      ],
      graphLink: [
        {
          source: "test0",
          target: "test2",
          value: "包括",
        },
      ],
      structureNode: [
        {
          node_id: 100,
          node_name: "牵引车",
          level: 0,
          pos: 1,
          score: 14,
        },
      ],
      InventorRelationLink: [
        { from_id: "100", to_id: 101, edge_name: "包括", weight: 1 },
      ],
    };
  },
  watch: {
    structureData: {
      handler(newVal, oldVal) {
        console.log("apiData", newVal);
        // this.setOriginGraphChart()
        this.graphData = [];
        this.graphLink = [];
        let name_id_map = {};
        newVal.structureNode.forEach((i) => {
          if (i.patent_count > 0) {
            if (i.patent_count > this.maxNum) {
              this.maxNum = i.patent_count;
            }
            name_id_map[i.applicant_id] = i.inventor;
            this.graphData.push({
              node_id: i.applicant_id,
              company_id: i.company_id,
              name: i.inventor,
              value: i.patent_count,
              category: i.type == "applicant" ? i.applicant_type : 0,
            });
          }
          // i.node_list.forEach(j => {
          //   this.graphData.push({
          //     node_id: parseInt(i.applicant_id.toString() + j.node_id.toString()),
          //     company_id: "",
          //     name: j.node_name + i.applicant_id.toString() + j.node_id.toString(),
          //     value: "",
          //     category: 1
          //   })
          //   this.graphLink.push({
          //     source: i.inventor,
          //     target: j.node_name + i.applicant_id.toString() + j.node_id.toString(),
          //     value: ""
          //   })
          // })
        });
        newVal.structureLink.forEach((i) => {
          this.graphLink.push({
            source: name_id_map[i.from_id],
            target: name_id_map[i.to_id],
            value: i.weight,
          });
        });
        this.setGraphChart();
      },
      deep: true,
    },
  },
  methods: {
    // 对象数组根据属性去重
    unique(arr, attr) {
      const res = new Map();
      return arr.filter((a) => !res.has(a[attr]) && res.set(a[attr], 1));
    },
    // echart初始化
    setOriginGraphChart() {
      if (this.graphChart) {
        console.log("毁灭");
        echarts.init(this.$refs.graphChartCompany).dispose();
      }
      // this.$nextTick(() => {
      this.graphChart = echarts.init(this.$refs.graphChartCompany);
      // })

      let that = this;
      // this.graphChart.on("mouseover", function(params) {
      //   console.log("鼠标覆盖", params)
      //   that.graphLink.forEach(i => {
      //     if (i.source == params.data.name || i.target == params.data.name) {
      //       i.lineStyle = {
      //         normal: {
      //           color: "blue"
      //         }
      //       }
      //     }

      //   })
      //   that.graphChart.setOption({
      //     links: that.graphLink
      //   })
      // })
      // this.graphChart.on("mouseout", function(params) {
      //   console.log("鼠标离开", params)
      //   that.graphChart.setOption({
      //     series: [
      //       {
      //         lineStyle: {
      //           normal: {
      //             color: that.graphData.length > 50 ? "#F6F5F5" : "#aaa"
      //           }
      //         }
      //       }
      //     ]
      //   });
      // })
      this.graphChart.on("click", function(params) {
        console.log("公司关系图点击", params);
        // if (params.data.category == 3 || params.data.category == 4) {
        if (params.dataType == "edge" || !params.data.company_id) return;
        console.log("bbbb", params.data.company_id);
        EventBus.$emit("relation_data", {
          company_id: params.data.company_id,
          node_id: params.data.node_id,
        });
        // EventBus.$emit("relation_company_id", params.data.company_id);
        // EventBus.$emit("relation_node_id", params.data.node_id);
        //   that.structureData.structureNode.forEach(i => {
        //     if (i.inventor == params.data.name) {
        //       i.node_list.forEach(j => {
        //     that.graphData.push({
        //       node_id: parseInt(i.applicant_id.toString() + j.node_id.toString()),
        //       company_id: "",
        //       name: j.node_name + i.applicant_id.toString() + j.node_id.toString(),
        //       value: "",
        //       category: 1
        //     })
        //     that.graphLink.push({
        //       source: i.inventor,
        //       target: j.node_name + i.applicant_id.toString() + j.node_id.toString(),
        //       value: ""
        //     })
        //   })
        //     }

        // });
        console.log("graphData", that.graphData);
        console.log("graphLink", that.graphLink);
        // that.setGraphChart()
        //   console.log("params", params.data)
        //   EventBus.$emit("searchIndustryNews", params.data.name)
        // }
      });
    },
    // 渲染echart
    setGraphChart() {
      let option = {
        legend: {
          x: "left",
          data: ["产业", "关联企业", "院校", "个人"],
          show: true,
          textStyle: {
            fontSize: 13, //字体大小
            color: "#333333", //字体颜色
          },
        },
        animation: false,
        animationDurationUpdate: 1500,
        animationEasingUpdate: "quinticInOut",
        tooltip: {
          formatter: function(params) {
            // console.log("tooltip", params);
            if (params.dataType == "edge") {
              return `${params.data.source} 共有${params.data.value} 个【${params.data.target} 】相关专利`;
            } else {
              // if (params.data.category != 0) {
              if (("" + params.data.node_id).includes("n")) {
                // 产业节点
                return `【${params.data.name}】共有${params.data.value}个专利`;
              }
              return `${params.data.name} 共有${params.data.value}个专利`;
              // } else {
              //   return `共有${params.value}个专利`;
              // }
            }
          },
        },
        series: [
          {
            type: "graph",
            layout: "none",
            force: {
              // layoutAnimation: false,
              edgeLength: 20,
              repulsion: 100,
              // repulsion: Math.floor(Math.random()*200 + 1),
              gravity: 0.5,
              friction: 0.1,
            },
            focusNodeAdjacency: true,
            roam: true,
            // zoom: "0.5",
            zoom: "1",
            lineStyle: {
              // 关系边的公用线条样式。其中 lineStyle.color 支持设置为'source'或者'target'特殊值，此时边会自动取源节点或目标节点的颜色作为自己的颜色。
              normal: {
                color: "#aaa", // 线的颜色[ default: '#aaa' ]
                width: 10, // 线宽[ default: 1 ]
                type: "solid", // 线的类型[ default: solid ]   'dashed'    'dotted'
                opacity: 1, // 图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形。[ default: 0.5 ]
                curveness: 0.5, // 边的曲度，支持从 0 到 1 的值，值越大曲度越大。[ default: 0 ]
              },
            },
            categories: [
              {
                name: "产业",
                symbol: "roundRect",
                itemStyle: {
                  label: {
                    normal: {
                      show: false,
                      formatter: function(params) {
                        return params.data.name.replace(/\d+./g, "");
                      },
                      // position: "inside",
                      textStyle: {
                        fontSize: 12,
                        color: "black",
                      },
                    },
                    emphasis: {
                      show: true,
                      position: "inside",
                      formatter: function(params) {
                        return params.data.name.replace(/\d+./g, "");
                      },
                      textStyle: {
                        fontSize: 12,
                        color: "black",
                      },
                    },
                  },
                  normal: {
                    color: "#1E90FF",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                // symbolSize: 40,
                zlevel:-1
              },
              {
                name: "关联企业",
                itemStyle: {
                  normal: {
                    color: "#FF5C91",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                // symbolSize: 40,
                zlevel:-1
              },
              {
                name: "院校",
                itemStyle: {
                  normal: {
                    color: "#00CED1",
                  },
                  nodeStyle: {
                    brushType: "both",

                    borderWidth: 1,
                  },
                },
                // symbolSize: 40,
              },
              {
                name: "个人",
                itemStyle: {
                  normal: {
                    color: "#4B0082",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                // symbolSize: 40,
              },
              // {
              //   name: "该节点公司",
              //   // symbol: "roundRect",
              //   itemStyle: {
              //     normal: {
              //       color: "orange",
              //     },
              //     nodeStyle: {
              //       brushType: "both",
              //       borderWidth: 1,
              //     },
              //   },
              //   // symbolSize: 40,
              // },
              // {
              //   name: "五级",
              //   itemStyle: {
              //     normal: {
              //       color: "#EA7CCC",
              //     },
              //     nodeStyle: {
              //       brushType: "both",
              //       borderWidth: 1,
              //     },
              //   },
              //   symbolSize: 40,
              // },
              // {
              //   name: "六级",
              //   itemStyle: {
              //     normal: {
              //       color: "#FC8452",
              //     },
              //     nodeStyle: {
              //       brushType: "both",
              //       borderWidth: 1,
              //     },
              //   },
              //   symbolSize: 40,
              // },
            ],
            label: {
              normal: {
                show: false,
                formatter: function(params) {
                  return params.data.name.replace(/\d+./g, "");
                },
                // position: "inside",
                textStyle: {
                  fontSize: 12,
                  color: "black",
                },
              },
              emphasis: {
                show: true,
                position: "inside",
                formatter: function(params) {
                  return params.data.name.replace(/\d+./g, "");
                },
                textStyle: {
                  fontSize: 12,
                  color: "black",
                },
              },
            },
            edgeSymbol: ["none", "none"],
            edgeSymbolSize: [10, 7],
            edgeLabel: {
              normal: {
                // show: false,
                textStyle: {
                  fontSize: 13,
                },
                // formatter: "{c}",
                formatter: "合作",
              },
            },
            // data: graph_info["欣旺达"].data.nodes,
            // data: this.graphData,
            data: this.graphData.map((node, index) => {
              return {
                node_id: node.node_id,
                name: node.name,
                value: node.value,
                category: node.category,
                company_id: node.company_id,
                x: Math.floor(Math.random() * 200 + 1),
                y: Math.floor(Math.random() * 200 + 1),
                // symbolSize: node.value * 2
                symbolSize:
                  node.category != 0
                    ? (node.value / this.maxNum) * 100 + 20
                    : 20,
              };
            }),
            // links: graph_info["欣旺达"].data.edge,
            links: this.graphLink,
            // lineStyle: {
            //   normal: {
            //     opacity: 0.9,
            //     width: 1,
            //     curveness: 0.3,
            //     color: this.graphData.length > 50 ? "#F6F5F5" : "#aaa",
            //   },
            //   emphasis: {
            //     color: "#337AB7",
            //     width: 2,
            //   },
            // },
            lineStyle: {
              normal: {
                opacity: 0.9,
                width: 1,
                curveness: 0.3,
              },
              // emphasis: {
              //   color: "#337AB7",
              //   curveness: 0.3,
              //   width: 2,
              // },
            },
          },
        ],
      };
      this.graphChart.setOption(option);
    },
  },
  mounted() {
    this.setOriginGraphChart();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.companyRelationLink {
  width: 100%;
  height: 100%;
  .graphContainer {
    width: 96%;
    margin-left: 2%;
    // height: 450px;
    height: 100%;
  }
  .graphChartCompany {
    width: 100%;
    // height: 30vw;
    height: 100%;
    margin-top: 5px;
  }
  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 50%;
    }
  }
}
</style>
