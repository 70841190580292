<template>
  <div class="inventorRelationLink">
    <div class="graphContainer">
      <div class="graphChart" ref="graphChart"></div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../utils/eventBus.js";
export default {
  name: "InventorRelationLink",
  props: {
    structureData: Object,
  },
  data() {
    return {
      maxNum: 0,
      graphChart: "",
      color: [
        "#73c0de",
        "#3ba272",
        "#fc8452",
        "#9a60b4",
        "#ea7ccc",
        "pink",
        "purple",
        "skyblue",
        "rgb(254,067,101)",
        "rgb(252,157,154)",
        "rgb(249,205,173)",
        "rgb(200,200,169)",
        "rgb(131,175,155)",
        "rgb(138,151,123)",
        "rgb(244,208,0)",
        "rgb(229,131,8)",
        "rgb(220,87,18)",
        "rgb(69,137,148)",
        "rgb(114,83,52)",
        "rgb(36,33,28)",
      ],
      categories: [],
      graphData: [
        {
          // id: 100,
          name: "test0",
          category: 0,
        },
      ],
      graphLink: [
        {
          source: "test0",
          target: "test2",
          value: "包括",
        },
      ],
      structureNode: [
        {
          node_id: 100,
          node_name: "牵引车",
          level: 0,
          pos: 1,
          score: 14,
        },
      ],
      InventorRelationLink: [
        { from_id: "100", to_id: 101, edge_name: "包括", weight: 1 },
      ],
    };
  },
  watch: {
    structureData: {
      handler(newVal, oldVal) {
        console.log("apiData", newVal);
        // this.setOriginGraphChart()
        this.graphData = [];
        this.graphLink = [];
        this.categories = [];
        let name_id_map = {};
        this.maxNum = 0;
        newVal.legendData.forEach((i, index) => {
          this.categories.push({
            name: i,
            itemStyle: {
              label: {
                show: true,
              },
              normal: {
                color: this.color[index],
              },
              nodeStyle: {
                brushType: "both",
                borderWidth: 1,
              },
            },
          });
        });
        newVal.structureNode.forEach((i) => {
          name_id_map[i.inventor_id] = i.inventor;
          if (i.patent_count > 0) {
            if (i.patent_count > this.maxNum) {
              this.maxNum = i.patent_count;
            }
            this.graphData.push({
              node_id: i.inventor_id,
              name: i.inventor,
              value: i.patent_count,
              // category: Math.floor(Math.random()*4 + 1)
              category: i.short_name,
            });
          }
        });
        newVal.structureLink.forEach((i) => {
          this.graphLink.push({
            source: name_id_map[i.from_id],
            target: name_id_map[i.to_id],
            value: i.weight,
          });
        });
        this.$nextTick(() => {
          this.setGraphChart();
        });
      },
      deep: true,
    },
  },
  methods: {
    // 对象数组根据属性去重
    unique(arr, attr) {
      const res = new Map();
      return arr.filter((a) => !res.has(a[attr]) && res.set(a[attr], 1));
    },
    // echart初始化
    setOriginGraphChart() {
      echarts.init(this.$refs.graphChart).dispose();
      this.graphChart = echarts.init(this.$refs.graphChart);
      this.graphChart.on("click", function(params) {
        console.log("图表点击：", params);
        // if (params.data.category == 3 || params.data.category == 4) {
        if (params.dataType == "node") {
          console.log("专利发明人关系图点击", params);
          EventBus.$emit("choose_inventor", params.data.name);
        }

        // EventBus.$emit("searchIndustryNews", params.data.name)
        // }
      });
    },
    // 渲染echart
    setGraphChart() {
      let option = {
        // legend: {
        //   x: "left",
        //   data: [
        //     "一级",
        //     "二级",
        //     "三级",
        //     "四级",
        //     "五级",
        //     "六级",
        //   ],
        //   show: false,
        //   textStyle: {
        //     fontSize: 13, //字体大小
        //     color: "#333333", //字体颜色
        //   },
        // },
        legend: {
          x: "left",
          type: "scroll",
          data: this.structureData.legendData,
          show: true,
          textStyle: {
            fontSize: 14, //字体大小
            color: "#333333", //字体颜色
          },
        },
        // animation: false,
        // animationDurationUpdate: 1500,
        // animationEasingUpdate: 'quinticInOut',
        tooltip: {
          formatter: function(params) {
            console.log("tooltip", params);
            if (params.dataType == "edge") {
              return `${params.data.source}与${params.data.target}共合作发明 ${params.data.value} 个专利`;
            } else {
              return `${params.data.name}共发明 ${params.data.value} 个专利`;
            }
          },
        },
        animation: false,
        series: [
          {
            type: "graph",
            layout: "force",
            // force: {
            //   layoutAnimation: false,
            //   repulsion: 1000,
            // },
            // focusNodeAdjacency: true,
            force: {
              // layoutAnimation: false,
              edgeLength: 20,
              repulsion: 200,
              gravity: 0.5,
              friction: 0.1,
            },
            focusNodeAdjacency: true,
            //symbolSize: 65,
            // force: {
            //   layoutAnimation: "false",
            //   initLayout: "circular"
            // },
            roam: true,
            zoom: "1",
            //categories:this.ca,
            lineStyle: {
              // 关系边的公用线条样式。其中 lineStyle.color 支持设置为'source'或者'target'特殊值，此时边会自动取源节点或目标节点的颜色作为自己的颜色。
              normal: {
                color: "#aaa", // 线的颜色[ default: '#aaa' ]
                width: 10, // 线宽[ default: 1 ]
                type: "solid", // 线的类型[ default: solid ]   'dashed'    'dotted'
                opacity: 1, // 图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形。[ default: 0.5 ]
                curveness: 0.5, // 边的曲度，支持从 0 到 1 的值，值越大曲度越大。[ default: 0 ]
              },
            },
            categories: this.categories,
            /*
            categories: [
              {
                name: "一级",
                itemStyle: {
                  label: {
                    show: true,
                  },
                  normal: {
                    color: "orange",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                symbolSize: 40,
              },
              {
                name: "二级",
                // symbol: "roundRect",
                itemStyle: {
                  normal: {
                    color: "#1E90FF",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                symbolSize: 40,
              },
              {
                name: "三级",
                itemStyle: {
                  normal: {
                    color: "#00CED1",
                  },
                  nodeStyle: {
                    brushType: "both",

                    borderWidth: 1,
                  },
                },
                symbolSize: 50,
              },
              {
                name: "四级",
                // symbol: "roundRect",
                itemStyle: {
                  normal: {
                    color: "#4B0082",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                symbolSize: 50,
              },
              {
                name: "五级",
                itemStyle: {
                  normal: {
                    color: "#9C5288",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                symbolSize: 50,
              },
              {
                name: "六级",
                itemStyle: {
                  normal: {
                    color: "#FC8452",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                symbolSize: 50,
              },
            ],
            */
            label: {
              normal: {
                show: false,
                //position:'top',
                textStyle: {
                  fontSize: 12,
                  // color: "black"
                },
              },
            },
            edgeSymbol: ["none", "none"],
            edgeSymbolSize: [10, 7],
            edgeLabel: {
              normal: {
                show: false,
                textStyle: {
                  fontSize: 13,
                },
                // formatter: "{c}",
                formatter: "合作",
              },
            },
            // data: graph_info["欣旺达"].data.nodes,
            // data: this.graphData,
            data: this.graphData.map((node) => {
              return {
                node_id: node.node_id,
                name: node.name,
                value: node.value,
                category: node.category,
                // symbolSize: node.value * 2
                symbolSize: (node.value / this.maxNum) * 100 + 10,
              };
            }),
            // links: graph_info["欣旺达"].data.edge,
            links: this.graphLink,
            lineStyle: {
              normal: {
                opacity: 0.9,
                width: 1,
                curveness: 0.3,
              },
            },
          },
        ],
      };
      this.graphChart.setOption(option);
    },
  },
  mounted() {
    this.setOriginGraphChart();
  },
  destroyed() {
    console.log("图表毁灭");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.inventorRelationLink {
  width: 100%;
  height: 100%;
  .graphContainer {
    width: 96%;
    margin-left: 2%;
    // height: 450px;
    height: 100%;
  }
  .graphChart {
    width: 100%;
    // height: 30vw;
    height: 100%;
    margin-top: 5px;
  }
  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 50%;
    }
  }
}
</style>
