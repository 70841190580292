<template>
  <div class="techRoute">
    <div style="margin-left: 10px;padding-top: 20px;display: flex">
      <div class="color-class" style="display: flex" v-for="i in colorList" :key="i.name" @click="chooseType(i.id)">
        <div class="color-box" :class="getBoxClass(i.id)"></div>
        <p>{{i.name}}</p>
      </div>
    </div>
    <div style="display: flex;margin-left: 10px;margin-top: 10px;padding-bottom: 10px;cursor: pointer;">
      <div style="margin-right: 10px" @click="scale=scale/1.5">🔍-</div>
      <div @click="scale=scale*1.5">🔍+</div>
    </div>
    <div>
    <div class="routeTree" :style="`transform: scale(${scale}, ${scale})`" v-drag>
      <div style="display: flex;" v-for="(r, index) in routeList" :key="r.year" >
        <div class="routeTree-item">
          <div v-if="index % 2 != 0" style="height: 320px"></div>
          <div v-if="index % 2 != 0"  :class="[getClass(r.year), checkSelect(r.year)? 'b-select' : 'b-unselect']">{{ r.year }}</div>
          <el-timeline
            :reverse="index % 2 != 0"
            :class="
              index % 2 != 0 ? 'elTimeline rotateZ' : 'elTimeline rotateF'
            "
          >
            <el-timeline-item
              v-for="(activity, index) in r.activities"
              :key="index"
            >
              <p :class="activity.focus == true ? 'focus' : 'normal'">
                {{ activity.content }}
              </p>
            </el-timeline-item>
          </el-timeline>
          <div v-if="index % 2 == 0"  :class="[getClass(r.year), checkSelect(r.year)? 'b-select' : 'b-unselect']">{{ r.year }}</div>
          <div v-if="index % 2 == 0" style="height: 320px"></div>
        </div>
        <div
          v-if="index != routeList.length - 1"
          class="routeTree-line"
          style="margin-top: 340px"
        ></div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import api from "@/api/api";
import cookie from "@/utils/common.js";
import { EventBus } from "../utils/eventBus.js";

export default {
  components: {},
  data() {
    return {
      scale: 1,
      colorList: [
        {
          name: "全部",
          id: 0
        },
        {
          name: "锰酸锂动力电池",
          id: 1
        },
        {
          name: "三元锂动力电池",
          id: 2
        },
        {
          name: "磷酸铁锂动力电池",
          id: 3
        },
        {
          name: "固态锂动力电池",
          id: 4
        },
      ],
      type1: ["1982", "1996"],
      type2: ["1999", "2001", "2012", "2019"],
      type3: ["1997", "2000", "2010", "2016", "2020", "2021"],
      type4: ["2013", "2017", "2025", "2027"],
      typeTotal: ["1976", "1980", "1987", "1992", "1995",  "1982", "1996", "1999", "2001", "2012", "2019", "1997", "2000", "2010", "2016", "2020", "2021", "2013", "2017", "2025", "2027"],
      typeSelect: ["1976", "1980", "1987", "1992", "1995",  "1982", "1996", "1999", "2001", "2012", "2019", "1997", "2000", "2010", "2016", "2020", "2021", "2013", "2017", "2025", "2027"],
      activities: [
        {
          content: "SOP规定",
        },
        {
          content: "PAD宽度不符",
        },
        {
          content: "抓料位置偏移",
        },
        {
          content: "角度修正故障",
        },
      ],
      routeList: [
        {
          year: "1976",
          activities: [
            {
              content: "埃克森公司申请了第一件电池专利",
              focus: false,
            },
            {
              content: "正极材料硫化钛",
              focus: false,
            },
            {
              content: "负极材料金属锂",
              focus: false,
            },
            {
              content: "专利: US4009052A",
              focus: false,
            },
          ]
        },
        {
          year: "1980",
          activities: [
            {
              content: "应用于3C产品",
              focus: false
            },
            {
              content: "发明钴酸锂电池",
              focus: false
            },
            {
              content: "约翰 · 古迪纳夫",
              focus: false
            },
          ]
        },
        {
          year: "1982",
          activities: [
            {
              content: "萨克雷",
              focus: false
            },
            {
              content: "发表材料研究报告",
              focus: false
            },
            {
              content: "尖晶石锰酸锂电池",
              focus: false
            },
          ]
        },
        {
          year: "1987",
          activities: [
            {
              content: "专利: US4963161A",
              focus: false
            },
            {
              content: "专利: US4753859A",
              focus: false
            },
            {
              content: "软包封装但易鼓包胀气",
              focus: false
            },
            {
              content: "凝胶态电解质",
              focus: false
            },
            {
              content: "贝尔实验室",
              focus: false
            },
          ]
        },
        {
          year: "1992",
          activities: [
            {
              content: "索尼",
              focus: false
            },
            {
              content: "18650圆柱电池",
              focus: false
            },
            {
              content: "正极：钴酸锂",
              focus: false
            },
            {
              content: "负极：石墨",
              focus: false
            },
            {
              content: "专利: JP2955135B2",
              focus: false
            },
          ]
        },
        {
          year: "1995",
          activities: [
            {
              content: "专利: JP3897387B2",
              focus: false
            },
            {
              content: "提出NCA正极",
              focus: false
            },
            {
              content: "日本",
              focus: false
            },
          ]
        },
        {
          year: "1996",
          activities: [
            {
              content: "Moli能源公司首次商用",
              focus: false
            },
            {
              content: "锰酸锂正极材料电池",
              focus: false
            },
          ]
        },
        {
          year: "1997",
          activities: [
            {
              content: "专利: US60016060P0",
              focus: false
            },
            {
              content: "磷酸铁锂的可逆性嵌入脱出锂离子性能LiXFePO4正极材料",
              focus: false
            },
            {
              content: "美国goodenough教授团队报道",
              focus: false
            },
            {
              content: "日本报道磷酸铁锂的橄榄石型晶体特征",
              focus: false
            },
          ]
        },
        {
          year: "1999",
          activities: [
            {
              content: "日本中央电气工业株式会社",
              focus: false
            },
            {
              content: "共沉淀法制备阳离子NiCoMn三元材料",
              focus: false
            },
          ]
        },
        {
          year: "2000",
          activities: [
            {
              content: "专利: CA2320661A1",
              focus: false
            },
            {
              content: "磷酸铁锂包碳技术",
              focus: false
            },
          ]
        },
        {
          year: "2001",
          activities: [
            {
              content: "美国IIion Technology采用固相法制备NiCoMn",
              focus: false
            },
            {
              content: "阿贡实验室提出富锂高锰三元材料",
              focus: false
            },
            {
              content: "3M集团提出NCM三元复合正极材料",
              focus: false
            },
            {
              content: "专利: US6964828B2、US6660432B2",
              focus: false
            },
            {
              content: "专利: US6680143B2、US6677082B2",
              focus: false
            },
          ]
        },
        {
          year: "2010",
          activities: [
            {
              content: "磷酸铁锂电池电动车商用",
              focus: false
            },
            {
              content: "美国A123",
              focus: false
            },
          ]
        },
        {
          year: "2012",
          activities: [
            {
              content: "美国特斯拉Model s发布",
              focus: false
            },
            {
              content: "使用三元锂电池",
              focus: false
            },
          ]
        },
        {
          year: "2013",
          activities: [
            {
              content: "研发基于铁和硫的合成阴极固态锂电池",
              focus: false
            },
            {
              content: "美国科罗拉多大学波德分校",
              focus: false
            },
          ]
        },
        {
          year: "2016",
          activities: [
            {
              content: "美国特斯拉Model 3",
              focus: false
            },
            {
              content: "使用磷酸铁锂电池",
              focus: false
            },
          ]
        },
        {
          year: "2017",
          activities: [
            {
              content: "解决固态锂电池技术瓶颈",
              focus: false
            },
            {
              content: "美国goodenough团队",
              focus: false
            },
          ]
        },
        {
          year: "2019",
          activities: [
            {
              content: "中国宁德时代",
              focus: false
            },
            {
              content: "将811三元锂电池产业化",
              focus: false
            },
          ]
        },
        {
          year: "2020",
          activities: [
            {
              content: "使用磷酸铁锂电池",
              focus: false
            },
            {
              content: "商用电动车宋PLUS",
              focus: false
            },
            {
              content: "中国比亚迪",
              focus: false
            },
          ]
        },
        {
          year: "2021",
          activities: [
            {
              content: "中国比亚迪",
              focus: false
            },
            {
              content: "商用电动车汉",
              focus: false
            },
            {
              content: "使用新一代磷酸铁锂电池",
              focus: false
            },
            {
              content: "刀片电池",
              focus: false
            },
          ]
        },
        {
          year: "2025",
          activities: [
            {
              content: "半固态锂电池有望商用",
              focus: false
            },
            {
              content: "丰田、大众、宝马、松下等",
              focus: false
            },
          ]
        },
        {
          year: "2027",
          activities: [
            {
              content: "LG化学",
              focus: false
            },
            {
              content: "计划实现全固态电池商业化",
              focus: false
            },
          ]
        },
      ],
    };
  },
  computed: {
    isOrNot() {
      if (this.formData.is_listed == 1) {
        return "是";
      } else {
        return "否";
      }
    },
  },
  directives: {
    drag: {
      // 指令的定义
      bind: function (el) {
        let oDiv = el; // 获取当前元素
        oDiv.onmousedown = (e) => {
          // 算出鼠标相对元素的位置
          let disX = e.clientX - oDiv.offsetLeft;
          let disY = e.clientY - oDiv.offsetTop;

          document.onmousemove = (e) => {
            // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
            let left = e.clientX - disX;
            let top = e.clientY - disY;

            oDiv.style.left = left + "px";
            oDiv.style.top = top + "px";
          };

          document.onmouseup = (e) => {
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      },
    },
    
  },
  methods: {
    // 选择左上角类别
    chooseType(id) {
      console.log("选择左上角类别", id)
      if (id == 0) {
        this.typeSelect = this.typeTotal
      } else if (id == 1) {
        this.typeSelect = this.type1
      } else if (id == 2)  {
        this.typeSelect = this.type2
      } else if (id == 3)  {
        this.typeSelect = this.type3
      } else if (id == 4)  {
        this.typeSelect = this.type4
      }
    },
    getClass(year) {
      console.log("year", year)
      if (this.type1.indexOf(year) != -1) {
        console.log("111")
        return "routeTree-year"
      } else if (this.type2.indexOf(year) != -1) {
        console.log("222")
        return "routeTree-year2"
      } else if (this.type3.indexOf(year) != -1) {
        console.log("333")
        return "routeTree-year3"
      } else  if (this.type4.indexOf(year) != -1) {
        console.log("444")
        return "routeTree-year4"
      } else {
        return "routeTree-year5"
      }
    },
    checkSelect(year) {
      if (this.typeSelect.indexOf(year) != -1) {
        return true
      } else {
        return false
      }
    },
    getBoxClass(val) {
      if (val ==0) {
        return "color-box0"
      } else if (val == 1) {
        return "color-box"
      } else if (val == 2) {
        return "color-box2"
      } else if (val == 3) {
        return "color-box3"
      } else if (val == 4) {
        return "color-box4"
      }
    }
  },
  mounted() {},
  beforeDestroy() {},
};
</script>

<style lang="scss">
.techRoute {
  background-color: white;
  margin-left: 4px;
  border-radius: 6px;
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
  // width: 100%;
  // padding-left: 100px;
  .elTimeline {
    margin-top: 20px;
    height: 300px;
    // transform: rotate(30deg);
  }
  .el-timeline-item__node--::after {
    content: "";
    background-color: #E4E7ED;
    width: 3px;
    height: 50px;
  }
  .el-timeline-item__tail {
    border: none;
  }
  .rotateZ {
    transform: rotate(25deg) translateX(-40px) translateY(40px);
    // padding-top: 10px;
    .focus {
      background-color: #548235;
      color: white;
      border-radius: 4px;
      padding: 2px 4px 2px 4px;
    }
    .normal,
    .focus {
      transform: rotate(-25deg) translateY(-25px);
    }
  }
  .rotateF {
    transform: rotate(-25deg) translateX(-10px) translateY(-10px);
    display: flex;
    flex-direction: column-reverse;
    // padding-bottom: 10px;
    .focus {
      background-color: #548235;
      color: white;
      border-radius: 4px;
      padding: 2px 4px 2px 4px;
    }
    .normal,
    .focus {
      transform: rotate(25deg) translateY(25px);
    }
  }
  // .focus {
  //   background-color: #548235;
  //   color: white;
  //   border-radius: 4px;
  //   padding: 2px 4px 2px 4px;
  // }
  // .normal, .focus {
  //   transform: rotate(-25deg);
  // }
  .routeTree {
    cursor: pointer;
    display: flex;
    width: 80%;
    height: 30%;
    position: absolute;
    // overflow-x: scroll;
    // margin-top: 2vw;
    margin-top: 10px;
    top: 30vh;
    margin-left: 2vw;
    align-items: center;
    padding-left: 100px;
    // padding-top: 100px;
    padding-right: 10%;
    // height: 60vh;
    &-item {
      width: 150px;
      // min-height: 25vw;
      // border: 1px solid black;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-year, &-year2, &-year3, &-year4, &-year5 {
      width: 100%;
      height: 40px;
      background-color: #4472C4;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-year2 {
      background-color: orange;
    }
    &-year3 {
      background-color: rgb(238, 59, 89);
    }
    &-year4 {
      background-color: rgb(38, 197, 85);
    }
    &-year5 {
      background-color: #18cce4
    }
    &-line {
      width: 60px;
      height: 2px;
      background-color: black;
    }
  }
  .color {
    &-class {
      margin-bottom: 5px;
      margin-right: 15px;
      cursor: pointer;
    }
    &-box, &-box2, &-box3, &-box4, &-box0 {
      background-color: #4472C4;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
    &-box2 {
      background-color: orange;
    }
    &-box3 {
      background-color: rgb(238, 59, 89);
    }
    &-box4 {
      background-color: rgb(38, 197, 85);
    }
    &-box0 {
      background-color: gray;
    }
  }
  .b {
    &-select {
      // background-color: orange;
    }
    &-unselect {
      background-color: gray;
    }
    
  }
}
</style>
